<surf-header class="l-page__header"
             [emulationInProgress]="emulationInProgress"
             [emulatingUser]="emulatingTradeClient"
             [isStartEmulationOptionAvailable]="true"
             [enableCacheUrlRedirect]="true"
             (startStopEmulation)="startStopEmulation($event)"
             (onSelectBrand)="selectBrand($event)"
             (openAndCloseSurfClientSearchModal)="openAndCloseSurfClientSearchModal($event)"
             (onSelectCurrency)="selectCurrency($event)"
></surf-header>

<main class="l-page__main">
  <section class="dashboard" *ngIf="!enableDashboardDetailView">
    <surf-toaster-notification
      [hasConfirmButton]="true"
      [hasCancelButton]="true"
      [hasDuration]="false"
      [confirmLabel]="'View Quote'"
      [cancelLabel]="'Dismiss'"
      [duration]="10000"
      [message]="'Quote is successfully saved.'"
      [shortMessage]="'Quote ID '+bkgId"
      [active]="activeToaster"
      (actionItem)="onclickActionItem($event)"
    ></surf-toaster-notification>
    <div [ngClass]="!activeSearchFlow && ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW ? 'dashboard__search' : 'dashboard__search-block'">
      <div [ngClass]="!activeSearchFlow && ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW ? 'l-wrapper dashboard__search-wrapper' : 'l-wrapper dashboard__search-block-wrapper'">
        <div class="dashboard__search-bar auto-search-panel">
          <surf-search-panel (searchFlow)="selectSearchFlow($event)"></surf-search-panel>
        </div>
        <div class="dashboard__search-box" *ngIf="showSearchBar">
          <surf-linking-itineraries
            [loadAllResultsAsDefault]="true"
            [isLinkItinerary]="false"
            [(isBookingSearchInputFocussed)]="isBookingSearchInputFocussed"
            (goToDetailsView)="goToDetailsView()">
          </surf-linking-itineraries>
        </div>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'PKG'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-package-criteria  *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                                [paxTypeAges]="paxSubTypeAges"
                                [cachingItemMap]="cachingItemMap"
                                [allowNgrxCaching] = allowNgrxCaching
        ></surf-package-criteria>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'HTL'" class="dashboard__search-expand">
        <div class="l-wrapper">
          <surf-hotel-criteria-dp  *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                                   [paxTypeAges]="paxSubTypeAges"
                                   [isDPSearch]="false"></surf-hotel-criteria-dp>
        </div>
    </div>

    <div *ngIf="activeSearchFlow == 'FLT'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-flight-criteria-dp  *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                                  [paxTypeAges]="paxSubTypeAges"
                                  [isDPSearch]="false"
                                  [allowNgrxCaching]="allowNgrxCaching"
                                  [cachingItemMap]="cachingItemMap"
          (fltGrabPnrResFromFltCriteria) = "setFlightGrabPnrResult($event)"
          (showManualItemConfigOut) = "setManualItemConfigPnr($event)"
          (pnrComOutputFromFltCriteria) = "setGrabPNRCriteria($event)"
        ></surf-flight-criteria-dp>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'CAR'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-car-criteria-dp *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                              [paxTypeAges]="paxSubTypeAges"
                              [isDPSearch]="false"
        ></surf-car-criteria-dp>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'DP'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-dp-criteria *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                          [paxTypeAges]="paxSubTypeAges"
                          [allowNgrxCaching]="allowNgrxCaching"
                          [cachingItemMap]="cachingItemMap"
                          [keyControls]="getKeyControls()"></surf-dp-criteria>
      </div>

    </div>

    <div *ngIf="activeSearchFlow == 'TRS'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-transfer-criteria-dp  *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                                    [paxTypeAges]="paxSubTypeAges"
                                    [isDPSearch]="false"></surf-transfer-criteria-dp>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'CRU'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-cruise-criteria-dp
        [cachingItemMap]="cachingItemMap"
        [allowNgrxCaching] = allowNgrxCaching
        ></surf-cruise-criteria-dp>
      </div>
    </div>
    <div *ngIf="activeSearchFlow == 'TOU'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-tour-criteria-dp
          [keyControls]="getKeyControls()"
          [cachingItemMap]="cachingItemMap"
          [allowNgrxCaching] = allowNgrxCaching
        ></surf-tour-criteria-dp>
      </div>
    </div>

    <div *ngIf="activeSearchFlow == 'GEN'" class="dashboard__search-expand">
      <div class="l-wrapper">
        <surf-generic-criteria-dp  *ngIf="!allowNgrxCaching || checkModuleCaching(STORE_CONSTANTS.CACHE_PAX_SUB_TYPE_AGES)"
                                   [paxTypeAges]="paxSubTypeAges"
                                   [cachingItemMap]="cachingItemMap"
                                   [allowNgrxCaching] = allowNgrxCaching
        ></surf-generic-criteria-dp>
      </div>
    </div>

    <surf-add-manual-item [isActive]="activeSearchFlow == 'MAN'" [keyControls]="getKeyControls()"></surf-add-manual-item>

    <surf-add-manual-booking-item
      *ngIf="showManualItemConfig || showManualItemConfigPnr"
      [isActive]="activeSearchFlow == 'MANFLT' || activeSearchFlow == 'MAN_CRU' || showManualItemConfigPnr"
      [productCode]="showManualItemConfigPnr? 'FLT':activeMBIProductCode"
      [isDashboard]="true"
      [pnrComponentOutput]="pnrComOutput"
      [flightGrabPnrResult] = "flightGrabPnrResult"
      [isPnr]="showManualItemConfigPnr"
      [keyControls]="getKeyControls()"
      [changePassengersEnabled]="showManualItemConfigPnr? false:true"
      [airlineListInput]=""
      (emitOnClose)="setManualItemConfigPnr(!$event)">
    </surf-add-manual-booking-item>
    <surf-add-insurance *ngIf="showManualItemConfig" [isFromDashBoard]="true" [keyControls]="getKeyControls()"  [isActive]="activeSearchFlow == 'INS'" [productCode]="'INS'" [changePassengersEnabled]="true" ></surf-add-insurance>

    <ng-template [ngIf]="!ENABLE_DASHBOARD_HIGHLIGHTS_COLLAPSED_VIEW && activeSearchFlow == null">
      <surf-booking-summary
        (onSelectedDurationChanged)="setBookingSearchInputFocus()"
        (onToggleRetailStoreViewStatus)="onToggleRetailStoreViewStatus($event)">
      </surf-booking-summary>
    </ng-template>
<!--    -->
<!--    -->

  </section>

  <section class="dashboard" *ngIf="enableDashboardDetailView">
    <surf-toaster-notification
      [hasConfirmButton]="true"
      [hasCancelButton]="true"
      [hasDuration]="false"
      [confirmLabel]="'View Quote'"
      [cancelLabel]="'Dismiss'"
      [duration]="10000"
      [message]="'Quote is successfully saved.'"
      [shortMessage]="'Quote ID '+bkgId"
      [active]="activeToaster"
      (actionItem)="onclickActionItem($event)"
    ></surf-toaster-notification>
    <surf-booking-summary (goToDashboardDetailsView)="backToLandingView()"></surf-booking-summary>
  </section>

</main>
<surf-modal
  [active]="isSurfClientSearchModalActive"
  [contentClass]="'c-client-search_modal'"
  [hasCancelButton]="false"
  [hasConfirmButton]="false"
  [disableConfirm]="false"
  (clickButton)="handleSurfClientSearchModal($event)">

  <surf-client-search-modal
    (openAndCloseSurfClientSearchModal)="openAndCloseSurfClientSearchModal($event)"
    (selectClient)="selectTheEmulatingClient($event)"
  >
  </surf-client-search-modal>
</surf-modal>

<!-- Emulation warning message box -->
<surf-message-box [title]="'Please Note'"
                  [active]="activeEmulationWarning"
                  [hasConfirmButon]="true"
                  [hasCancelButton]="true"
                  [confirmLabel]="'Proceed'"
                  [cancelLabel]="'Cancel'"
                  [svgName]="'exclamation'"
                  [type]="'warning'"
                  (clickButton)="handleEmulationWarning($event)">
  <span>{{EmulationWarningText | surfTextCase: 'sentence'}}</span>
</surf-message-box>
<surf-footer [version]="version"></surf-footer>
