import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ConfigLoader, DataKey, DataStore} from '@tc-core/util/framework';
import {
  DataShareService,
  DestinationType,
  CarCriteria,
  KeyCodes,
  OntologyResponse,
  SurfScrollDirective,
  SurfTextCasePipe,
  HotelCriteria,
  SurfCriteriaUtil,
  TravellerType,
  SurfCityPipe,
  ConfigService,
  SurfComponentsConstants,
  CAR_CONTRACT_TYPES,
  SurfTravellerEarliestDepartureDateService,
  SurfTimePipe,
  DateTimeFormat,
  SurfCalendarStartDateService,
  SurfErrorMessages,
  SurfStaticDataService
} from '@surf/surf-components-core';
import {DataServiceHandler} from '@tc-core/service/service-handlers';
import {MultiFieldInputProperty, MultiFieldInputPropertyFactory} from '@surf/surf-multi-field-input';
import {PaxAllocationItem} from '@surf/surf-pax-selection';
import { validate } from 'codelyzer/walkerFactory/walkerFn';
import moment from 'moment';
import {TC} from '@tc-core/util';
import {element} from "protractor";
import { Subscription } from 'rxjs';
@Component({
  selector: 'surf-car-criteria',
  templateUrl: './car-criteria.component.html'
})
export class CarCriteriaComponent implements OnInit, OnChanges, OnDestroy {

  CAR_MAX_CABIN_PAX_COUNT = 20;
  CAR_MAX_CHILD = this.CAR_MAX_CABIN_PAX_COUNT - 1;
  CAR_MAX_TEEN = this.CAR_MAX_CABIN_PAX_COUNT - 1;
  CAR_MAX_INFANTS = this.CAR_MAX_CABIN_PAX_COUNT - 1;
  MAX_ROOMS = 9;
  CAR_MIN_ADULT = 1;
  CAR_MAX_ADULT = 20;
  CAR_MAX_PAX_COUNT = 40;
  DEFAULT_ADULT_AGE = 30;
  DEFAULT_DRIVER_AGE = 25;
  MIN_DRIVER_AGE = 18;
  MAX_DRIVER_AGE = 60;
  DEFAULT_OFFSET = 10;
  MAX_INFANT_AGE = 23;
  MAX_CHILD_AGE = 12;
  MAX_TEEN_AGE = 17;
  DEFAULT_CONTRACT_TYPE = 'CAR_HIRE';
  DEFAULT_MAX_CAR_COUNT = 5;
  INITIAL_DURATION = 3;
  MIN_SEARCH_QUERY_LENGTH = 3;
  STATIC_DATA_URL_BASE = "/data/data-service/v2/static-data";
  STATIC_DATA_FETCH_SIZE = 20;
  pickupDropoffUrl = '';
  DISABLE_DP_SWITCH = false;
  isFilterListError = false;
  isQueryError = false;
  FIELD_SEPARATOR = '~';
  AGE_SEPARATOR = '__';
  LESS_THAN_ONE = '< 1';
  CALENDAR_RANGE = 2;
  DRIVER_AGE_LABEL = '';
  CAR_SEARCH_RESULT_COUNT: string;
  TEEN_PAX_ACTIVE = true;
  CAR_SET_DEFAULT_DROPOFF_METHOD: string;
  autoFilled : boolean;
  setTimeoutValue = 200;

  @Input() isDPSearch: boolean = false;
  @Input() index: number;
  @Input() isRefine = false;
  @Input() windowScrollEnable = true;
  @Input() selectedPax: any;
  @Input() originalRefineCriteria: any;
  @Input() selectedDriverAge = -1;
  @Input() dpCarDestinations: any;
  //new impl
  @Input() dpEnableDate: any;
  @Input() dpInitialDateStr: any;
  @Input() dpInitialDestStr: any;
  @Input() dpCarRefineCriteria: any;
  @Input() isServiceAddComponent = false;
  @Input() cartPaxSelection: any;
  @Input() unfreeze = false;
  @Input() isAmendmentFlow = false;
  @Input() isItemCloseable = true;
  @Input() oldCriteriaItem;
  @Input() productKey: string;
  @Input() bookingId = null;
  @Input() amendmentReasonCode = null;
  @Input() amendmentCauseCode = null;
  @Input() paxSplitArray = [];
  @Input() enablePaxSplit = false;
  dpCalEnableDate: Date;
  @Input() triggerExternalSearch = '';
  @Input() paxTypeAges = [];

  @Output() cancelRefine: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyDownLast = new EventEmitter<boolean>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isUnfreeze = new EventEmitter<boolean>();
  @Output() getUrlCriteria: EventEmitter<any> = new EventEmitter();
  @Output() isPaxSplitChanged = new EventEmitter<boolean>();

  defaultPaxSet: Array<PaxAllocationItem> = [];
  firstDatePickerFocus = false;
  secondDatePickerFocus = false;
  enterPressed = false;
  carCriteria: CarCriteria = new CarCriteria();
  productCriteriaList = [];
  moduleConfigs: any;
  keyParams: string;
  destinations = [];
  airportsLoaded = false;
  citiesLoaded = false;
  isInvalidTime = false;
  typeAheadPickupHasError = false;
  typeAheadDropoffHasError = false;
  pickUpProperty: MultiFieldInputProperty;
  dropOffProperty: MultiFieldInputProperty;
  pickUpDropOffProperties: MultiFieldInputProperty[] = [];
  selectedPickUpPoint = new OntologyResponse();
  selectedDropOffPoint = new OntologyResponse();
  pickUpDropOffValues = [];
  filteredDestinations = [];
  returnedProperty: MultiFieldInputProperty;
  pickUpInitialDate = [];
  dropOffInitialDate = [];
  driverValidAgesDropDownList = [];
  serviceCriteria;

  calendarMinDate: Date = new Date();
  selectedPickupCriteria: any;
  selectedDropCriteria: any;
  previousSelectedPickupCriteria: any;
  previousSelectedDropCriteria: any;
  selectedPickupDate: Date;
  selectedDropoffDate: Date;
  guestComponentFocus = false;
  currentPaxOption = 0;
  resetPickupCalendar = false;
  resetdropCalendar = false;
  triggerChange: boolean;
  showTypeAheadContent = true;
  dpPreItemDate: Date = null;
  dpPreItemToDate: Date = null;
  preItemDayGap = 0;
  dpDropOffDisplayDate: Date;
  typeAheadErrorMsg = '';
  userCaption = '';
  textCasePipe: SurfTextCasePipe;
  isLastItemInMultiInput = false; // check is focusing input is last input in multi input
  pressEscPickup: false;
  pressEscDropoff: false;
  paxDisplay: string;
  carContractTypes;
  selectedContractType: string;
  contractTypeReset = false;
  freeze: boolean; // enable/disable all input fields during amendment search
  typeWarning = false;
  ageWarning = false;
  pickUpDateWarning = false;
  dropOffDateWarning = false;
  CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES;
  CSG_VAN_CRITERIA_ENABLE = true;
  subscriptions = new Subscription();
  paxSplitDisplayText = 'All Guests';
  ENABLE_PAX_SPLIT = false;
  ENABLE_CAR_NFR = false;
  driverAgeToolTipText = '';
  defaultPickUpAndDropOffTimes;

  // pickUp
  defaultPickUpMeridian = 'AM';
  defaultPickUpTimesHours = '10';
  defaultPickUpTimesMinutes = '0';

  // dropOff
  defaultDropOffMeridian = 'PM';
  defaultDropOffTimesHours = '3';
  defaultDropOffTimesMinutes = '0';
  cityPipe: SurfCityPipe;
  recommendingDropOffData = null;
  enableDropOffRecommendation  = false;
  showDropOffRecommendations = false;
  focusingIndex = null;
  lastKeyEnter = false;
  selectCarWithOutEnter = false;

  hideErrorMsg = true;
  validateDisabledDateSelection = false;
  SURF_ERROR_MESSAGES = SurfErrorMessages;
  enableDOBMandatory = false;

  dropOffCurrentDateOfTimeZone : Date = this.calendarMinDate;
  isPickUpTimeLaterThanDropOffTime : boolean = false;
  skipInnerInputErrorValidations : boolean = true;
  isSecondInputError : boolean = false;
  readonly PICKUP_LATER_THAN_DROP_OFF_ERROR_MESSAGE : string  = "Please make sure the drop-off time is later than the pick-up time.";

  constructor(protected commonService: DataShareService,
              protected dataStore: DataStore,
              protected configLoader: ConfigLoader,
              protected dataServiceHandler: DataServiceHandler,
              protected configService: ConfigService,
              public surfCriteriaUtil: SurfCriteriaUtil,
              protected surfCalendarStartDateService: SurfCalendarStartDateService,
              protected surfStaticDataService: SurfStaticDataService,
              protected travellerEarliestDepartureDateService: SurfTravellerEarliestDepartureDateService) {
    this.subscriptions.add(
      this.commonService.getProductCriteriaList().subscribe(list => {
        this.productCriteriaList = list;
      }));
    this.cityPipe = new SurfCityPipe(surfStaticDataService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unfreeze && this.unfreeze !== undefined) {
      this.freeze = !this.unfreeze;
    }
    if (changes.selectedPax) {
      this.selectedPax = changes.selectedPax.currentValue;
      if (this.isRefine) {
        this.defaultPaxSet = this.selectedPax;
      }
      if (this.isServiceAddComponent) {
        const adults = this.selectedPax.reduce( (acc, pax ) => acc + pax.adult , 0 ) ;
        const child = this.selectedPax.reduce( (acc, pax) => acc + pax.child.length , 0) ;
        const teen = this.selectedPax.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
        const infant = this.selectedPax.reduce( (acc, pax) => acc + pax.infant.length , 0 ) ;
        this.paxDisplay = this.generateDisplayString(adults, child, infant, teen) ;
      }
    }
    if (changes.cartPaxSelection && this.cartPaxSelection) {
      const adults = this.cartPaxSelection.reduce( (acc, pax ) => acc + pax.adult , 0 )
      const child = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.child.length , 0)
      const teen = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.teen.length , 0) ;
      const infant = this.cartPaxSelection.reduce( (acc, pax) => acc + pax.infant.length , 0 )
      this.paxDisplay = this.generateDisplayString(adults, child, infant, teen);
      this.getPassengerSelection(this.cartPaxSelection);
    }
    if (changes['dpEnableDate']) {
      this.dpEnableDate = changes['dpEnableDate'].currentValue;
      if (this.dpEnableDate && this.isAmendmentFlow && this.isDPSearch) {
          this.dpCalEnableDate = new Date(this.dpEnableDate);
      } else {
        this.dpCalEnableDate = new Date();
      }
      this.validateInputsForDP();
    }

    if (changes['paxSplitArray']) {
      const selectedPaxArray = this.paxSplitArray.filter(pax => {
        return pax.selected;
      });
      if (selectedPaxArray && selectedPaxArray.length === this.paxSplitArray.length) {
        this.paxSplitDisplayText = 'All Guests';
      } else {
        this.paxSplitDisplayText = selectedPaxArray.length.toString() + (selectedPaxArray.length === 1 ? ' Guest' : ' Guests');
      }
    }

    if (changes['dpInitialDateStr']) {
      this.dpInitialDateStr = changes['dpInitialDateStr'].currentValue;
      if (this.dpInitialDateStr && this.dpInitialDateStr.split('~')[0] && this.dpInitialDateStr.split('~')[1] &&
        this.dpInitialDateStr != "Invalid date~Invalid date") {
        this.dpDropOffDisplayDate = new Date(this.dpInitialDateStr.split('~')[0]);
        this.setInitialDates(this.dpInitialDateStr.split('~')[0], this.dpInitialDateStr.split('~')[1]);
        this.validateInputsForDP();
      }
    }

    if (changes['dpInitialDestStr']) {
      this.dpInitialDestStr = changes['dpInitialDestStr'].currentValue;
      if (this.dpInitialDestStr != 'undefined') {
        this.selectedPickUpPoint.displayText = this.dpInitialDestStr;
        this.selectedDropOffPoint.displayText = this.dpInitialDestStr;
        this.validateInputsForDP();
      }
      if (!this.dpInitialDestStr && this.isAmendmentFlow) {
        if (!(this.isDPSearch && this.freeze)) {
          this.typeAheadErrorMsg = 'The previous location is unavailable. Please select a location and continue';
          this.isFilterListError = true;
          this.userCaption = '';
        } else {
          this.userCaption =
            'Looks like the previous location is unavailable. Select this item to amend the location. Alternatively click Search if you wish to continue without amending the location.';
          this.isFilterListError = false;
        }
      } else {
        this.typeAheadErrorMsg = '';
        this.userCaption = '';
        this.isFilterListError = false;
      }
    }

    if (changes.triggerExternalSearch && this.triggerExternalSearch && this.triggerExternalSearch === 'CAR') {
      this.searchCars(true);
      setTimeout(() => {
        this.triggerExternalSearch = '';
      }, 10);
    }

    // if(changes.pickUpProperty){
    //
    // }
  }

  ngOnInit() {
    this.loadConfigs();

    this.DRIVER_AGE_LABEL = this.enableDOBMandatory ? "Driver\'s age at travel date*" : "Driver\'s age*"
    this.driverAgeToolTipText = this.enableDOBMandatory ?
      "Driver’s age is mandatory, and a minimum age\nrequirement may apply to make a car reservation.\nDOB and age will be compared and need to match\nto complete a booking." :
      "Driver’s age is mandatory and should\nbe over " + this.MIN_DRIVER_AGE + "* to make a car reservation.";

    if (!this.paxTypeAges || this.paxTypeAges.length === 0) {
      this.loadPaxTypeAges();
    } else {
      this.mapPaxSubTypeAges();
    }
    this.setDefaultPickUpAndDropOffTimes();
    this.driverValidAgesDropDownList = this.generateDriverAgeList();

    this.subscriptions.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg != undefined && msg.split('_')[0] === 'resetDest') {
          let indices = msg.split('_')[1].split('~');
          if (indices && indices.length > 0) {
            indices.forEach(i => {
              if (i == this.index.toString()) {
                let changeParam = msg.split('_')[2];
                if (changeParam) {
                  this.selectedPickUpPoint.displayText = changeParam;
                  this.selectedDropOffPoint.displayText = changeParam;
                  this.validateInputsForDP();
                  if (this.isAmendmentFlow && !this.isItemCloseable) {
                    this.checkLocationChange(null);
                    this.checkAgeChange();
                  }
                }
              }
            });
          }
        }
      }));

    this.subscriptions.add(
      this.commonService.getComponentCriteria('CAR').subscribe(cri => {
        this.carCriteria = cri as CarCriteria;
        if (this.dpCarRefineCriteria) {
          this.carCriteria = this.dpCarRefineCriteria as CarCriteria;
          if (this.isAmendmentFlow && this.cartPaxSelection) {
            this.getPassengerSelection(this.cartPaxSelection);
          }
        }
      }));
    this.subscriptions.add(
      this.commonService.getKeyParamString().subscribe(keyParams => {
        // update resultsCount
        if (this.CAR_SEARCH_RESULT_COUNT && this.CAR_SEARCH_RESULT_COUNT.length > 0) {
          const tempList = keyParams.split('resultsCount=');
          if (tempList[1]) {
            const sliceIndex = tempList[1].indexOf('&') >= 0 ? tempList[1].indexOf('&') : tempList[1].length;
            tempList[1] = this.CAR_SEARCH_RESULT_COUNT + tempList[1].slice(sliceIndex);
            keyParams = tempList.join('resultsCount=');
          } else {
            tempList[1] = this.CAR_SEARCH_RESULT_COUNT;
            keyParams = tempList.join('&resultsCount=');
          }
        }
        this.keyParams = keyParams;
      }));

    this.subscriptions.add(
      this.commonService.getCurrentMsg().subscribe(msg => {
        if (msg == 'triggerReset') {
          this.resetInputs();
        }
      }));
    this.createCarContractTypes();
    if (this.carCriteria.contractType) { this.setSelectedCarContractType(this.carCriteria.contractType); }
    if (this.isRefine && !this.isDPSearch) {
      this.setInitialDates(this.carCriteria.pickupDateTime, this.carCriteria.returnDateTime);
      this.selectedPickUpPoint.displayText = this.carCriteria.pickupLocationName ? this.carCriteria.pickupLocationName : this.carCriteria.pickUpName;
      this.selectedDropOffPoint.displayText = this.carCriteria.returnLocationName ? this.carCriteria.returnLocationName : this.carCriteria.dropName;
    }
    if((this.selectedPickUpPoint.displayText === undefined || this.selectedDropOffPoint.displayText === undefined)
      && this.dpInitialDestStr && this.dpInitialDestStr !== 'null'){
      this.selectedPickUpPoint.displayText = this.carCriteria.pickupLocationName ? this.carCriteria.pickupLocationName : this.carCriteria.pickUpName;
      this.selectedDropOffPoint.displayText = this.carCriteria.returnLocationName ? this.carCriteria.returnLocationName : this.carCriteria.dropName;
      if (this.carCriteria.pickupDateTime) {
        this.selectedPickupDate = new Date(this.carCriteria.pickupDateTime);
        this.dropOffCurrentDateOfTimeZone = new Date(this.selectedPickupDate.getTime());
      }
      if (this.carCriteria.returnDateTime) { this.selectedDropoffDate = new Date(this.carCriteria.returnDateTime); }
      if (this.carCriteria.pickupDateTime && this.carCriteria.returnDateTime) {
        this.setInitialDates(this.carCriteria.pickupDateTime, this.carCriteria.returnDateTime);
      }
      this.validateInputsForDP();
    } else if (this.isDPSearch && (this.dpInitialDestStr === undefined || this.dpInitialDestStr === null)) {
      this.selectedPickUpPoint.displayText = '';
      this.selectedDropOffPoint.displayText = '';
    }

    this.pickUpProperty = MultiFieldInputPropertyFactory.getTypeAheadProperties('Search location', true, false, 'PICKUP', 'PICK-UP*', 'strict', this.selectedPickUpPoint, this.MIN_SEARCH_QUERY_LENGTH, true, this.pickupDropoffUrl);
    this.dropOffProperty = MultiFieldInputPropertyFactory.getTypeAheadProperties('Search location', true, false, 'DROP', 'DROP-OFF*', 'strict', this.selectedDropOffPoint, this.MIN_SEARCH_QUERY_LENGTH, true, this.pickupDropoffUrl);
    this.pickUpDropOffProperties.push(this.pickUpProperty);
    this.pickUpDropOffProperties[0].warning = false;
    this.pickUpDropOffProperties.push(this.dropOffProperty);
    this.pickUpDropOffProperties[1].warning = false;

    /*Load cities and airports during the component flow only*/
    // if (!this.isDPSearch) {
      // this.dataServiceHandler.getCitiesWithDetails('all').subscribe(
      //   res => {
      //     if (res && res.data) {
      //       const cities = res.data;
      //       this.surfCriteriaUtil.updateValue(cities);
      //       this.citiesLoaded = true;
      //       cities.forEach(city => {
      //         this.destinations.push(city);
      //         if (this.isRefine && (this.carCriteria.destinationType === DestinationType.CITY) && (this.carCriteria.destinationCode === city.code)) {
      //           this.carCriteria.destinationName = city.name;
      //           this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
      //           if (this.isDPSearch) {
      //             // this.updateDPCriteria('dest-name');
      //           }
      //         }
      //       });
      //       if (this.airportsLoaded) {
      //         this.pickUpDropOffValues.push(this.destinations);
      //         this.pickUpDropOffValues.push(this.destinations);
      //         this.showTypeAheadContent = true;
      //       }
      //     }
      //   },
      //   error => {
      //     console.log('Error in getting cities');
      //   });
      //
      // this.dataServiceHandler.getAirports().subscribe(
      //   res => {
      //     if (res) {
      //       const airports = res;
      //       this.surfCriteriaUtil.updateValue(airports);
      //       this.airportsLoaded = true;
      //       airports.forEach(airport => {
      //         this.destinations.push(airport);
      //         if (this.isRefine && (this.carCriteria.destinationType === DestinationType.COUNTRY) && (this.carCriteria.destinationCode === airport.code)) {
      //           this.carCriteria.destinationName = airport.name;
      //           this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
      //           if (this.isDPSearch) {
      //             // this.updateDPCriteria('dest-name');
      //           }
      //         }
      //       });
      //       // console.log(this.destinations);
      //       if (this.citiesLoaded) {
      //         this.pickUpDropOffValues.push(this.destinations);
      //         this.pickUpDropOffValues.push(this.destinations);
      //         this.showTypeAheadContent = true;
      //       }
      //     }
      //   },
      //   error => {
      //     console.log('Error in getting Countries');
      //   });
    // }
    // else{
    //   this.pickUpDropOffValues.push(this.dpCarDestinations);
    // }

    if (this.isRefine && this.dpCarRefineCriteria) {
      this.selectedPickUpPoint.displayText = this.dpCarRefineCriteria.pickUpName ?
        this.dpCarRefineCriteria.pickUpName :
        (this.dpCarRefineCriteria.pickupLocationName ?
          this.dpCarRefineCriteria.pickupLocationName :
          this.dpInitialDestStr);
      this.selectedDropOffPoint.displayText = this.dpCarRefineCriteria.dropName ?
        this.dpCarRefineCriteria.dropName :
        (this.dpCarRefineCriteria.returnLocationName ?
          this.dpCarRefineCriteria.returnLocationName :
          this.dpInitialDestStr);
      this.validateInputsForDP();
    }

    this.showTypeAheadContent = true;
    this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'CAR');
    this.showDropOffRecommendations = true;
  }

  public ngOnDestroy(): void {
    if (this.subscriptions && !this.subscriptions.closed) {
      this.subscriptions.unsubscribe();
    }
  }

  loadPaxTypeAges() {
    this.subscriptions.add(
      this.dataServiceHandler.getPaxSubTypeAges().subscribe(
        res => {
          if (res == null || !(Array.isArray(res)) || res.length === 0) {
            console.log('Could not fetch Pax Type Ages');
            return;
          }

          this.paxTypeAges = res[0].paxTypes;
          this.mapPaxSubTypeAges();
        },
        error => {
          console.log('Error in getting pax sub type ages');
        }
      ));
  }
  private mapPaxSubTypeAges() {
    this.MAX_CHILD_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'C').age.upper / 12);
    this.MAX_TEEN_AGE = Math.floor(this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'T').age.upper / 12);
    this.MAX_INFANT_AGE = this.paxTypeAges.find(paxTypeAge => paxTypeAge.code === 'I').age.upper;
  }

  /**
   * set calendar initial dates based on the config values
   */
  setInitialDates(pickUpDate: any, dropOffDate: any) {
    this.pickUpInitialDate = [];
    this.dropOffInitialDate = [];
    let jsPickUp = new Date(pickUpDate);
    this.selectedPickupDate = jsPickUp;
    this.dropOffCurrentDateOfTimeZone = new Date(this.selectedPickupDate.getTime());
    if (this.isDPSearch) {
      this.dpDropOffDisplayDate = this.selectedPickupDate;
    }
    this.pickUpInitialDate.push(this.selectedPickupDate);

    if (dropOffDate != 'Invalid date') {
      let jsDropOff = new Date(dropOffDate);
      if (!isNaN(jsDropOff.getTime())) {
        this.selectedDropoffDate = jsDropOff;
        this.dropOffInitialDate.push(this.selectedDropoffDate);
      }
    }
    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(false);
    }
  }

  updateLocationCriteria(displayText, code) {
    this.selectedPickUpPoint.displayText = displayText;
    this.selectedDropOffPoint.displayText = displayText;
    this.carCriteria.pickupCity = code;
    this.carCriteria.pickupLocation = code;
    this.carCriteria.returnCity = code;
    this.carCriteria.returnLocation = code;
  }

  closeCriteriaBox() {
    this.commonService.changeMessage('CAR_close');
    this.commonService.updateComponentCriteria(null, 'CAR');
    if (this.isRefine) {
      this.cancelRefineSearch();
    }
  }

  generateDriverAgeList() {
    const arr = [];
    arr.push({code: -1, value: "Select age", selected: false});
    for (let i = this.MIN_DRIVER_AGE; i <= this.MAX_DRIVER_AGE; i++) {
      if (this.selectedDriverAge === i ) {
        arr.push({code: i, value: i, selected: true});
      } else {
        arr.push({code: i, value: i, selected: false});
      }
    }
    if (this.selectedDriverAge < 0) {
      arr[0].selected = true;
    }
    return arr;
  }

  selectDriverAge(event) {
    this.selectedDriverAge = event.code;
    this.carCriteria.driverAge = this.selectedDriverAge;
    this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
    if(this.isDPSearch){
      /*NEW IMPL - notify the change
      *change value : for DRIVERAGE ->  DRIVERAGE*/
      let changeObj = {
        index: this.index,
        productCode: 'CAR',
        changeType: 'DRIVERAGE',
        value: this.carCriteria.driverAge
      };
      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkAgeChange();
    }
  }

  cancelRefineSearch() {
    this.cancelRefine.emit(this.isRefine);
    this.resetToOriginalRefineCriteria();
  }

  resetToOriginalRefineCriteria() {
    /*if (this.originalRefineCriteria) {
      this.defaultPaxSet = this.selectedPax;
      this.setInitialDates(this.originalRefineCriteria.startDate, this.originalRefineCriteria.nights);
      let surfTextCasePipe = new SurfTextCasePipe(this.configService);
      if (this.originalRefineCriteria.destinationType == DestinationType.CITY && this.originalRefineCriteria.destinationCode) {
        let city = this.destinations.filter(des => des.code == this.originalRefineCriteria.destinationCode);
        let parent = this.getCountry(city[0]);
        this.hotelCriteria.destinationParentCode = parent;
        this.commonService.updateBaseCriteria(this.hotelCriteria);
        this.selectedDest.displayText = surfTextCasePipe.transform(this.hotelCriteria.destinationName, 'title').trim() + ', ' + parent;
      }
      if (this.originalRefineCriteria.destinationType == DestinationType.COUNTRY) {
        this.selectedDest.displayText = surfTextCasePipe.transform(this.hotelCriteria.destinationName, 'title').trim();
      }
    }*/
  }

  /**
   * Upon clicking the reset button, all the input fields and corresponding
   * values in the criteria object will be reset
   */
  resetInputs(isRefine?: boolean) {
    if (!isRefine) {
      this.selectedDriverAge = -1;
      this.selectedPickUpPoint.displayText = '';
      this.selectedDropOffPoint.displayText = '';
      this.carCriteria.pickUpName = null;
      this.carCriteria.pickupCity = null;
      this.carCriteria.dropName = null;
      this.carCriteria.returnCity = null;
      this.carCriteria.pickupDateTime = null;
      this.carCriteria.returnDateTime = null;
      this.carCriteria.pickupLocation = null;
      this.carCriteria.pickupLocationName = null;
      this.carCriteria.pickupGrp = null;
      this.carCriteria.returnLocation = null;
      this.carCriteria.returnLocationName = null;
      this.carCriteria.returnGrp = null;
      this.carCriteria.adult = '';
      this.carCriteria.child = '';
      this.carCriteria.teen = '';
      this.carCriteria.driverAge = this.selectedDriverAge;
      this.driverValidAgesDropDownList = this.generateDriverAgeList();
      this.selectedDropCriteria = null;
      this.selectedPickupCriteria = null;
      this.pickUpInitialDate = [];
      this.dropOffInitialDate = [];
      this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
      this.resetDisplayItems();
      this.createCarContractTypes();
    } else {
      this.cancelRefineSearch();
    }
    if (this.isServiceAddComponent) {
      /*Here same emitter is used to emit the search cancel event for add service flows*/
      this.cancelRefine.emit(true);
    }
  }

  resetDisplayItems() {
    this.defaultPaxSet = [];
    this.resetPickupCalendar = true;
    this.resetdropCalendar = true;
    setTimeout(() => {
      this.resetPickupCalendar = false;
      this.resetdropCalendar = false;
      }, 0);
  }

  /**
   * Construct the Car search URL and redirect to the listing page
   */
  searchCars(isExternal?) {
    if (this.validateInputs()) {
      const basePath = '/';
      if (this.isServiceAddComponent || this.isAmendmentFlow){
        this.travellerEarliestDepartureDateService.addNewDepartureDate(this.carCriteria.pickupDateTime);
        this.travellerEarliestDepartureDateService.checkAndUpdateEarliestDepartureDate();
        this.carCriteria.driverAge = this.selectedDriverAge;
        this.carCriteria.noOfCars = String(1);
        const criteriaUrl = basePath + 'b2b-cars/?' + this.keyParams + this.getCarSearchUrlParams();
        this.getUrlCriteria.emit({url: criteriaUrl, isExternal});
      } else {
        this.carCriteria.driverAge = this.selectedDriverAge;
        this.carCriteria.noOfCars = String(1);
        // this.isRefine ? window.location.origin + '/' : window.location.href;
        window.location.href = basePath + 'b2b-cars/?' + this.keyParams + this.getCarSearchUrlParams();
      }
    }
  }

  getCarSearchUrlParams() {
    /*Consider check-in date when calculating DOB*/
    if (this.carCriteria.adult) {
      // this.carCriteria.adult = this.surfCriteriaUtil.replaceDOB(this.carCriteria.adult, this.carCriteria.pickupDateTime);
      this.carCriteria.adult = this.carCriteria.adult.replace(/DOB/g, ''); // removing DOB from adult
    }

    let childParams = '';
    if (this.carCriteria.teen && this.carCriteria.teen.length > 0) {
      // this.carCriteria.child = this.surfCriteriaUtil.replaceDOB(this.carCriteria.child, this.carCriteria.pickupDateTime);
      this.carCriteria.teen = this.carCriteria.teen.replace(/DOB/g, ''); // removing DOB from child
      childParams = '&child=' + this.carCriteria.teen;
    }
    if (this.carCriteria.child && this.carCriteria.child.length > 0) {
      if (childParams.toString().includes('&child')) {
        this.carCriteria.child = this.carCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = childParams + this.AGE_SEPARATOR + this.carCriteria.child;
      } else {
        this.carCriteria.child = this.carCriteria.child.replace(/DOB/g, ''); // removing DOB from child
        childParams = '&child=' + this.carCriteria.child;
      }
    }
    if (this.carCriteria.infant && this.carCriteria.infant.length > 0) {
      // this.carCriteria.infant = this.carCriteria.infant.replace(/DOB/g, '');
      childParams += '&infant=' + this.carCriteria.infant;
    }

    let locationParams = '';
    if (this.carCriteria.pickupLocation !== null && this.carCriteria.pickupLocationName !== null) {
      locationParams += '&pickupLocation=' + this.carCriteria.pickupLocation + '&pickupLocationName=' + this.carCriteria.pickupLocationName;
      locationParams += '&pickupCity=' + this.carCriteria.pickupCity;
    } else if (this.carCriteria.pickupGrp !== null && this.carCriteria.pickUpName !== null && this.carCriteria.pickupCity !== null) {
      locationParams += '&pickupGrp=' + this.carCriteria.pickupGrp + '&pickUpName=' + this.carCriteria.pickUpName + '&pickupCity=' + this.carCriteria.pickupCity;
    } else if (this.carCriteria.pickupGrp !== null && this.carCriteria.pickUpName !== null && this.carCriteria.pickupCity === null) {
      locationParams += '&pickupGrp=' + this.carCriteria.pickupGrp + '&pickUpName=' + this.carCriteria.pickUpName;
    } else if (this.carCriteria.pickupCity !== null && this.carCriteria.pickUpName !== null) {
      locationParams += '&pickupCity=' + this.carCriteria.pickupCity + '&pickUpName=' + this.carCriteria.pickUpName;
    }

    if (this.carCriteria.returnLocation !== null && this.carCriteria.returnLocationName !== null) {
      locationParams += '&returnLocation=' + this.carCriteria.returnLocation + '&returnLocationName=' + this.carCriteria.returnLocationName;
      locationParams += '&returnCity=' + this.carCriteria.returnCity;
    } else if (this.carCriteria.returnGrp !== null && this.carCriteria.dropName !== null && this.carCriteria.returnCity !== null) {
      locationParams += '&returnGrp=' + this.carCriteria.returnGrp + '&dropName=' + this.carCriteria.dropName + '&returnCity=' + this.carCriteria.returnCity;
    } else if (this.carCriteria.returnGrp !== null && this.carCriteria.dropName !== null) {
      locationParams += '&returnGrp=' + this.carCriteria.returnGrp + '&dropName=' + this.carCriteria.dropName;
    } else if (this.carCriteria.returnCity !== null && this.carCriteria.dropName !== null) {
      locationParams += '&returnCity=' + this.carCriteria.returnCity + '&dropName=' + this.carCriteria.dropName;
    }

    // get car related URL params
    let queryStr = '&adult=' + this.carCriteria.adult + childParams + '&pickupDateTime=' + this.carCriteria.pickupDateTime + '&returnDateTime=' + this.carCriteria.returnDateTime
      + locationParams + '&driverAge=' + this.selectedDriverAge + '&contractType=' + this.carCriteria.contractType;


    if (this.ENABLE_CAR_NFR) {
      queryStr += '&expand=summary,detail,rate,carProduct,source,content,choices';
    } else {
      queryStr += '&expand=all';
    }

    if (this.isAmendmentFlow) {
      queryStr += '&bkgId=' + this.bookingId;
      queryStr += '&productKey=' + this.productKey;
      queryStr += '&isAmendment=true';
      queryStr += '&reason=' + this.amendmentReasonCode;
      queryStr += '&cause=' + this.amendmentCauseCode;
    }

    return queryStr;
  }

  validateInputs(): boolean {
    // if (this.isServiceAddComponent) {
    //   const pax = this.selectedPax[0];
    //   let adultText  = '~30~DOB';
    //   let childText  = '~6~DOB';
    //   let infantText =  '~~';
    //   adultText = pax.adult + adultText;
    //   this.carCriteria.adult = adultText;
    //   if (pax.child.length > 0) {
    //     childText = pax.child.length + childText ;
    //     this.carCriteria.child = childText;
    //   }
    //   if (pax.infant.length > 0) {
    //     infantText = pax.infant.length + infantText ;
    //     this.carCriteria.infant = infantText;
    //   }
    // }
    let isAllInputsPresent = false;
    this.isInvalidTime = false;
    let timeValid = true;
    if ((this.carCriteria.pickupCity || this.carCriteria.pickupGrp) && this.selectedPickUpPoint.displayText &&
      (this.carCriteria.returnCity || this.carCriteria.returnGrp) && this.carCriteria.adult &&
      this.selectedDropOffPoint.displayText && this.carCriteria.pickupDateTime && this.carCriteria.returnDateTime && this.selectedDriverAge > 0) {
      isAllInputsPresent = true;
    }

    if (this.selectedPickupDate && this.selectedDropoffDate &&
      (this.selectedPickupDate.getTime() >= this.selectedDropoffDate.getTime())) {
      timeValid = false;
      this.isInvalidTime = true;
    }
    const isLastMinuteBookingValidationPass = this.surfCalendarStartDateService.lastMinuteBookingErrorSet.size <= 0;
    if (this.isAmendmentFlow) {
      return isLastMinuteBookingValidationPass && isAllInputsPresent && timeValid;
    } else {
      return isAllInputsPresent && timeValid;
    }
  }

  validateInputsForDP() {
    let timeValid = true;
    if (this.selectedPickupDate == undefined || this.selectedDropoffDate == undefined) {
      timeValid = false;
    }

    if (this.selectedPickupDate && this.selectedDropoffDate && (this.selectedPickupDate.getTime() >= this.selectedDropoffDate.getTime())) {
      timeValid = false;
    }

    if (this.selectedPickUpPoint.displayText && this.selectedDropOffPoint.displayText && this.selectedDriverAge > 0) {
      this.isValid.emit(timeValid);
    } else {
      this.isValid.emit(false);
    }

  }

  scrollTo(selector) {
    if(!this.isDPSearch){
      if (this.windowScrollEnable) {
        SurfScrollDirective.scrollTo(
          selector,
          null,
          false,
          false,
          0,
          60
        );
      }
    }
  }

  loadConfigs() {
    this.STATIC_DATA_URL_BASE = this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]? this.configLoader.configurations.get(TC.CONF.CONF_ENDPOINT)[TC.ENDPOINT_ID.STATIC_DATA]: this.STATIC_DATA_URL_BASE;
    this.moduleConfigs = this.dataStore.get(DataKey.moduleConfiguration).getValue();

    if (this.moduleConfigs && this.moduleConfigs.length > 0) {
      this.moduleConfigs.forEach(mc => {
        switch (mc.code) {
          case 'MIN_SEARCH_QUERY_LENGTH':
            this.MIN_SEARCH_QUERY_LENGTH = mc.name;
            break;
          case 'MAX_ROOMS':
            this.MAX_ROOMS = mc.name;
            break;
          case 'CAR_MIN_ADULT':
            this.CAR_MIN_ADULT = mc.name;
            break;
          case 'CAR_MAX_ADULT':
            this.CAR_MAX_ADULT = mc.name;
            break;
          case 'CAR_MAX_PAX_COUNT':
            this.CAR_MAX_PAX_COUNT = mc.name;
            break;
          case 'CAR_MAX_CABIN_PAX_COUNT':
            this.CAR_MAX_CABIN_PAX_COUNT = mc.name;
            break;
          case 'DEFAULT_ADULT_AGE':
            this.DEFAULT_ADULT_AGE = mc.name;
            break;
          case 'INITIAL_DURATION':
            this.INITIAL_DURATION = mc.name;
            break;
          case 'DEFAULT_CALENDAR_OFFSET':
            this.DEFAULT_OFFSET = mc.name;
            break;
          case 'CALENDAR_RANGE':
            this.CALENDAR_RANGE = mc.name;
            break;
          case 'DEFAULT_DRIVER_AGE':
            this.DEFAULT_DRIVER_AGE = mc.name;
            break;
          case 'MIN_DRIVER_AGE':
            this.MIN_DRIVER_AGE = mc.name;
            break;
          case 'CAR_AUTO_POPULATING_PICKUP_DROPOFF_DEFAULT_TIMES':
            this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES = JSON.parse(mc.name);
            break;
          case 'MAX_DRIVER_AGE':
            this.MAX_DRIVER_AGE = mc.name;
            break;
          case 'DEFAULT_CONTRACT_TYPE':
            this.DEFAULT_CONTRACT_TYPE = mc.name;
            break;
          case 'DEFAULT_MAX_CAR_COUNT':
            this.DEFAULT_MAX_CAR_COUNT = mc.name;
            break;
          case 'DISABLE_DP_SWITCH':
            this.DISABLE_DP_SWITCH = (mc.name === 'true');
            break;
          case 'MAX_CHILD_AGE':
            this.MAX_CHILD_AGE = +mc.name;
            break;
          case 'MAX_INFANT_AGE':
            this.MAX_INFANT_AGE = +mc.name;
            break;
          case 'STATIC_DATA_FETCH_SIZE':
            this.STATIC_DATA_FETCH_SIZE = mc.name;
            break;
          case 'CSG_VAN_CRITERIA_ENABLE':
            this.CSG_VAN_CRITERIA_ENABLE = (mc.name === 'true');
            break;
          case 'CAR_SEARCH_RESULT_COUNT':
            this.CAR_SEARCH_RESULT_COUNT = mc.name;
            break;
          case 'TEEN_PAX_ACTIVE':
            this.TEEN_PAX_ACTIVE = ((mc.name as string) === 'true');
            break;
          case 'ENABLE_DROP_OFF_RECOMMENDATION':
            this.enableDropOffRecommendation = ((mc.name as string) === 'true');
            break;
        }
      });
    }
    const ENABLE_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('ENABLE_DP_PASSENGER_SPLIT', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_PAX_SPLIT;
    if (ENABLE_PAX_SPLIT_CONFIG) {
      ENABLE_PAX_SPLIT = ENABLE_PAX_SPLIT_CONFIG.toString().toUpperCase() === 'TRUE';
    }
    const ENABLE_CAR_PAX_SPLIT_CONFIG = this.configLoader.getModuleConfig('DP_SPLIT_PAX_ENABLED_PRODUCTS', TC.MODULE_NAME.SURF_B2B);
    let ENABLE_CAR_PAX_SPLIT;
    if (ENABLE_CAR_PAX_SPLIT_CONFIG) {
      ENABLE_CAR_PAX_SPLIT = ENABLE_CAR_PAX_SPLIT_CONFIG.toString().split(',').includes('CAR');
    }
    if (ENABLE_PAX_SPLIT && ENABLE_CAR_PAX_SPLIT) {
      this.ENABLE_PAX_SPLIT = true;
    }

    const CAR_SET_DEFAULT_DROPOFF_METHOD_CONFIG = this.configLoader.getModuleConfig('CAR_SET_DEFAULT_DROPOFF_METHOD', TC.MODULE_NAME.SURF_B2B);
    if (CAR_SET_DEFAULT_DROPOFF_METHOD_CONFIG) {
      this.CAR_SET_DEFAULT_DROPOFF_METHOD = CAR_SET_DEFAULT_DROPOFF_METHOD_CONFIG.toString();
    }
    else{
      this.CAR_SET_DEFAULT_DROPOFF_METHOD = 'PICKUP';
    }

    this.pickupDropoffUrl = this.STATIC_DATA_URL_BASE + '?dataTypes=SINGLE_CAR_LOCATION&userInput=[UI]&aggrLevel=NA&locale=en&matchType=CODE~NAME&expand=all&resultCount=' + this.STATIC_DATA_FETCH_SIZE;
    this.ENABLE_CAR_NFR = this.configLoader.getModuleConfig('ENABLE_CAR_NFR',TC.MODULE_NAME.SURF_B2B).toString().toUpperCase() === 'TRUE';

    const ENABLE_DOB_MANDATORY_IN_CAR_BOOK = this.configLoader.getModuleConfig('ENABLE_DOB_MANDATORY_IN_CAR_BOOK', TC.MODULE_NAME.SURF_B2B);
    if (ENABLE_DOB_MANDATORY_IN_CAR_BOOK) {
      this.enableDOBMandatory = ENABLE_DOB_MANDATORY_IN_CAR_BOOK.toString().toUpperCase() === 'YES';
    }
  }

  getDestinations(event) {
    if (!this.isDPSearch && event && event.property && event.property.label && event.property.label.includes('PICK-UP')) {
      event.property.id = 'PICKUP';
      this.showDropOffRecommendations = false;
      this.focusingIndex = null;
    } else if (!this.isDPSearch && event && event.property && event.property.label && event.property.label.includes('DROP-OFF')) {
      event.property.id = 'DROP';
    }
    if (event && event.filteredList && event.filteredList.length > 0) {
      this.showDropOffRecommendations = false;
      this.focusingIndex = null;
    } else {
      if (event.property.id !== 'PICKUP') {
        this.showDropOffRecommendations = true;
        this.focusingIndex = 1;
      }
    }
    this.returnedProperty = event.property;
    if (event.property.id == 'PICKUP') {
      this.typeAheadPickupHasError = false
    } else if (event.property.id == 'DROP') {
      this.typeAheadDropoffHasError = false;
    }
    if (event && event.filteredList && event.filteredList.length > 0) {
      this.showTypeAheadContent = true;
      this.returnedProperty = event.property;
      this.filteredDestinations = event.filteredList;
      this.isFilterListError = false;
      // console.log(event);

    } else {
      if (event.property && event.property.selectedItem && event.property.selectedItem.displayText.length < 3) {
        this.isFilterListError = false;
        if (event.property.id == 'PICKUP') {
          this.carCriteria.pickupCity = null;
          this.carCriteria.pickUpName = null;
          this.carCriteria.pickupLocation = null;
          this.carCriteria.pickupLocationName = null;
          this.carCriteria.pickupGrp = null;
        } else if (event.property.id == 'DROP') {
          this.carCriteria.returnCity = null;
          this.carCriteria.dropName = null;
          this.carCriteria.returnLocation = null;
          this.carCriteria.returnLocationName = null;
          this.carCriteria.returnGrp = null;
        }
      } else {
        if (event.property.id == 'PICKUP') {
          this.carCriteria.pickupCity = null;
          this.carCriteria.pickUpName = null;
          this.carCriteria.pickupLocation = null;
          this.carCriteria.pickupLocationName = null;
          this.carCriteria.pickupGrp = null;
          this.typeAheadPickupHasError = true;
        } else if (event.property.id == 'DROP') {
          this.carCriteria.returnCity = null;
          this.carCriteria.dropName = null;
          this.carCriteria.returnLocation = null;
          this.carCriteria.returnLocationName = null;
          this.carCriteria.returnGrp = null;
          this.typeAheadDropoffHasError = true;
        }
      }
      this.showTypeAheadContent = false;
    }

    if (this.typeAheadPickupHasError && this.typeAheadDropoffHasError) {
      this.isFilterListError = true;
      this.userCaption = '';
      this.typeAheadErrorMsg = 'Please enter valid pick-up and drop-off locations';
    } else if (this.typeAheadPickupHasError) {
      this.userCaption = '';
      this.isFilterListError = true;
      this.typeAheadErrorMsg = 'Please enter valid pick-up location';
    } else if (this.typeAheadDropoffHasError) {
      this.userCaption = '';
      this.isFilterListError = true;
      this.isSecondInputError = true;
      this.typeAheadErrorMsg = 'Please enter valid drop-off location';
    }
    if (this.carCriteria.productCode == undefined) {
      this.carCriteria.productCode = 'CAR';
    }
    this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
  }

  getCityCodeFromData(data) {
    if (data.type === 40) {
      return data.code;
    } else if (data.parent && data.parent.length > 0) {
      const filteredData = data.parent.filter(parent => parent.type === 40);
      if (filteredData.length > 0 && filteredData[0].code) {
        return filteredData[0].code;
      } else {
        return '';
      }
    }
    return '';
  }

  handleSelection(item, isRecommendSelection = false) {
    this.textCasePipe = new SurfTextCasePipe(this.configService);

    if (!this.firstDatePickerFocus && !this.selectedPickupDate) {
      setTimeout(() => {
        this.secondDatePickerFocus = false;
        this.guestComponentFocus = false;
        this.firstDatePickerFocus = true;
        this.isLastItemInMultiInput = false;
      }, this.setTimeoutValue);
    }
    if (!this.isDPSearch && this.CAR_SET_DEFAULT_DROPOFF_METHOD === 'CITY') {
      if (isRecommendSelection) {
        this.returnedProperty.id = 'DROP';
        this.showDropOffRecommendations = false;
        this.focusingIndex = null;
      } else {
        if (this.returnedProperty && this.returnedProperty.id !== 'DROP') {
          const code = this.getCityCodeFromData(item);
          if (code) {
            this.surfStaticDataService.getCarDropOffRecommendingData(code).subscribe(res => {
              if (res) {
                this.recommendingDropOffData = (res.data.filter(data => data.type === 40 || data.type === 90));
              }
            });
          }
        }
      }
    }
    if (item && this.returnedProperty) {
      this.showTypeAheadContent = false;
      if (this.returnedProperty.id === 'PICKUP') {
        this.showDropOffRecommendations = true;
        if (this.lastKeyEnter || this.isDPSearch) {
          this.selectCarWithOutEnter = false;
        } else {
          this.selectCarWithOutEnter = true;
        }
        this.focusingIndex = 1;
        this.selectedPickUpPoint.displayText = this.textCasePipe.transform(item.name, 'title').trim();
        this.selectedPickUpPoint.query = this.selectedPickUpPoint.displayText;
        this.selectedPickupCriteria = item;
        this.previousSelectedPickupCriteria = item;
        this.pressEscPickup = item.isPressEsc;
        // if CITY selected as pickup location
        this.calendarMinDate = new Date();
        for (let i = 0; i < item.attributes?.length ; i++) {
          if (item.attributes[i].name === 'current_time') {
            this.calendarMinDate = new Date(item.attributes[i].value);
          }
        }
        this.surfCalendarStartDateService.updateMinDate(this.calendarMinDate, 'CAR');
        if (item.type === 'CITY' || item.type === 40) {
          this.carCriteria.pickUpName = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
          this.carCriteria.pickupCity = this.selectedPickupCriteria.code;
          this.carCriteria.pickupLocation = null;
          this.carCriteria.pickupLocationName = null;
          this.carCriteria.pickupGrp = null;
        }

        if (item.type === 'SINGLE_CAR_LOCATION' || item.type === 90) {
          this.carCriteria.pickUpName = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
          this.carCriteria.pickupCity = this.selectedPickupCriteria.code;
          this.carCriteria.pickupLocation = this.selectedPickupCriteria.code;
          this.carCriteria.pickupLocationName = this.selectedPickupCriteria.name;
          this.carCriteria.pickupGrp = null;
        }

        // if AIRPORT selected as pickup location
        // if (item.type === 'AIRPORT' || item.type === 60) {
        //   this.carCriteria.pickUpName = null;
        //   this.carCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
        //   this.carCriteria.pickupLocation = this.selectedPickupCriteria.code;
        //   this.carCriteria.pickupLocationName = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
        //   this.carCriteria.pickupGrp = null;
        // }

        // if CAR_LOCATION selected as pickup location
        if (item.type === 'CAR_LOCATION' || item.type === 90) {
          this.carCriteria.pickUpName = null;
          this.carCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
          this.carCriteria.pickupLocation = this.selectedPickupCriteria.code;
          this.carCriteria.pickupLocationName = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
          this.carCriteria.pickupGrp = null;
          if (this.isAmendmentFlow && !this.isItemCloseable) {
            this.checkLocationChange(null);
          }
        }

        // if CAR_LOCATION selected as pickup location
        if (item.type === 'CAR_LOCATION_GROUP' || item.type === 140) {
          this.carCriteria.pickUpName = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
          this.carCriteria.pickupCity = this.surfCriteriaUtil.getCity(this.selectedPickupCriteria).code;
          this.carCriteria.pickupLocation = this.textCasePipe.transform(this.selectedPickupCriteria.name, 'title').trim();
          this.carCriteria.pickupLocationName = null;
          this.carCriteria.pickupGrp = this.selectedPickupCriteria.code;
          if (this.isAmendmentFlow && !this.isItemCloseable) {
            this.checkLocationChange(this.carCriteria.pickupGrp);
          }
        }

        if (this.CAR_SET_DEFAULT_DROPOFF_METHOD === 'PICKUP'){
          if(this.selectedDropOffPoint.displayText === '' || this.selectedDropOffPoint.displayText === null){
          this.selectedDropOffPoint.displayText = this.selectedPickUpPoint.displayText;
          this.selectedDropOffPoint.query = this.selectedPickUpPoint.displayText;
          this.autoFilled = true;
          this.selectedDropCriteria = item;
          this.previousSelectedDropCriteria = item;
          this.pressEscDropoff = item.isPressEsc;
          }

          // if CITY selected as pickup location
          if (item.type === 'CITY' || item.type === 40) {
            this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnCity = this.selectedDropCriteria.code;
            this.carCriteria.returnLocation = null;
            this.carCriteria.returnLocationName = null;
            this.carCriteria.returnGrp = null;
            this.isFilterListError = false;
          }

          // if CAR_LOCATION selected as pickup location
          if (item.type === 'CAR_LOCATION' || item.type === 90) {
            this.carCriteria.dropName = null;
            this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
            this.carCriteria.returnLocation = this.selectedDropCriteria.code;
            this.carCriteria.returnLocationName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnGrp = null;
            this.isFilterListError = false;
          }

          // if CAR_LOCATION selected as pickup location
          if (item.type === 'CAR_LOCATION_GROUP' || item.type === 140) {
            this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
            this.carCriteria.returnLocation = null;
            this.carCriteria.returnLocationName = null;
            this.carCriteria.returnGrp = this.selectedDropCriteria.code;
            this.isFilterListError = false;
          }

        }
        else
          if ((this.isDPSearch || !this.enableDropOffRecommendation) && !this.selectedDropCriteria) {
          this.selectedDropOffPoint.displayText = this.textCasePipe.transform(item.name, 'title').trim();
          this.selectedDropOffPoint.query = this.selectedDropOffPoint.displayText;
          this.selectedDropCriteria = item;
          this.previousSelectedDropCriteria = item;
          this.pressEscDropoff = item.isPressEsc;
          // if CITY selected as pickup location
          if (item.type === 'CITY' || item.type === 40) {
            this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnCity = this.selectedDropCriteria.code;
            this.carCriteria.returnLocation = null;
            this.carCriteria.returnLocationName = null;
            this.carCriteria.returnGrp = null;
            this.isFilterListError = false;
          }

          // if AIRPORT selected as pickup location
          // if (item.type === 'AIRPORT' || item.type === 60) {
          //   this.carCriteria.dropName = null;
          //   this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          //   this.carCriteria.returnLocation = this.selectedDropCriteria.code;
          //   this.carCriteria.returnLocationName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          //   this.carCriteria.returnGrp = null;
          //   this.isFilterListError = false;
          // }

          // if CAR_LOCATION selected as pickup location
          if (item.type === 'CAR_LOCATION' || item.type === 90) {
            this.carCriteria.dropName = null;
            this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
            this.carCriteria.returnLocation = this.selectedDropCriteria.code;
            this.carCriteria.returnLocationName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnGrp = null;
            this.isFilterListError = false;
          }

          // if CAR_LOCATION selected as pickup location
          if (item.type === 'CAR_LOCATION_GROUP' || item.type === 140) {
            this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
            this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
            this.carCriteria.returnLocation = null;
            this.carCriteria.returnLocationName = null;
            this.carCriteria.returnGrp = this.selectedDropCriteria.code;
            this.isFilterListError = false;
          }

        }
        this.triggerChange = !this.triggerChange;
      } else if (this.returnedProperty.id === 'DROP') {
        this.focusingIndex = null;
        this.selectedDropOffPoint.displayText = this.textCasePipe.transform(item.name, 'title').trim();
        this.selectedDropOffPoint.query = this.selectedDropOffPoint.displayText;
        this.autoFilled = false;
        this.selectedDropCriteria = item;
        this.previousSelectedDropCriteria = item;
        this.pressEscDropoff = item.isPressEsc;
        // if CITY selected as return location
        if (item.type === 'CITY' || item.type === 40) {
          this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          this.carCriteria.returnCity = this.selectedDropCriteria.code;
          this.carCriteria.returnLocation = null;
          this.carCriteria.returnLocationName = null;
          this.carCriteria.returnGrp = null;
          this.isFilterListError = false;

        }

        if (item.type === 'SINGLE_CAR_LOCATION' || item.type === 90) {
          this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          this.carCriteria.returnCity = this.selectedDropCriteria.code;
          this.carCriteria.returnLocation = this.selectedDropCriteria.code;
          this.carCriteria.returnLocationName = this.selectedDropCriteria.name;
          this.carCriteria.returnGrp = null;
          this.isFilterListError = false;

        }

        // if AIRPORT selected as return location
        // if (item.type === 'AIRPORT' || item.type === 60) {
        //   this.carCriteria.dropName = null;
        //   this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
        //   this.carCriteria.returnLocation = this.selectedDropCriteria.code;
        //   this.carCriteria.returnLocationName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
        //   this.carCriteria.returnGrp = null;
        //   this.isFilterListError = false;
        // }

        // if CAR_LOCATION selected as return location
        if (item.type === 'CAR_LOCATION' || item.type === 90) {
          this.carCriteria.dropName = null;
          this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          this.carCriteria.returnLocation = this.selectedDropCriteria.code;
          this.carCriteria.returnLocationName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          this.carCriteria.returnGrp = null;
          this.isFilterListError = false;
          if (this.isAmendmentFlow && !this.isItemCloseable) {
            this.checkLocationChange(null);
          }
        }

        // if CAR_LOCATION selected as return location
        if (item.type === 'CAR_LOCATION_GROUP' || item.type === 140) {
          this.carCriteria.dropName = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          this.carCriteria.returnCity = this.surfCriteriaUtil.getCity(this.selectedDropCriteria).code;
          this.carCriteria.returnLocation = this.textCasePipe.transform(this.selectedDropCriteria.name, 'title').trim();
          this.carCriteria.returnLocationName = null;
          this.carCriteria.returnGrp = this.selectedDropCriteria.code;
          this.isFilterListError = false;
          if (this.isAmendmentFlow && !this.isItemCloseable) {
            this.checkLocationChange(this.carCriteria.returnGrp);
          }
        }

        this.triggerChange = !this.triggerChange;
      }
      if (this.carCriteria.productCode == undefined) {
        this.carCriteria.productCode = 'CAR';
      }
      this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');

      if(this.isDPSearch){
        /*NEW IMPL - notify the change
        * change value : for DEST ->  PICKUPCITY~PICKUPLOCATION~RETURNCITY~RETURNLOCATION~~*/

        let value = this.carCriteria.pickupCity + '~' + this.carCriteria.pickupLocation + '~'
          + this.carCriteria.returnCity + '~' + this.carCriteria.returnLocation + '~' + item.name;

        let changeObj = {
          index : this.index,
          productCode : 'CAR',
          changeType : 'DEST',
          value : value
        };
        this.commonService.updateChangeItem(changeObj);
        this.validateInputsForDP();
      }
    }
  }

  getSelectedDateTime(event, pickup: boolean) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    this.resetdropCalendar = false;
    if (event && event.length > 0) {
      if (pickup) {
        this.selectedPickupDate = event[0];
        this.dropOffCurrentDateOfTimeZone = new Date(this.selectedPickupDate.getTime());
        if (this.isDPSearch) {
          this.dpDropOffDisplayDate = this.selectedPickupDate;
        }
        if (this.selectedDropoffDate && this.selectedDropoffDate.getTime() <= this.selectedPickupDate.getTime()) {
          this.selectedDropoffDate = null;
          this.isPickUpTimeLaterThanDropOffTime = false;
          this.carCriteria.returnDateTime = null;
          this.resetdropCalendar = true;
          if (this.isRefine || this.isDPSearch || this.isAmendmentFlow) {
            this.dropOffInitialDate = [];
          }
        }
        this.carCriteria.pickupDateTime = moment(this.selectedPickupDate).format(format);
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkDateChange(true);
        }
      } else {
        this.selectedDropoffDate = event[0];
        if (this.selectedPickupDate && this.selectedPickupDate.getTime() >= this.selectedDropoffDate.getTime()) {
          this.isPickUpTimeLaterThanDropOffTime = true;
        } else {
          this.isPickUpTimeLaterThanDropOffTime = false;
        }
        this.carCriteria.returnDateTime = moment(this.selectedDropoffDate).format(format);
        if (this.isAmendmentFlow && !this.isItemCloseable) {
          this.checkDateChange(false);
        }
      }
    }
    else {
      //executes when typed date is invalid
      if(pickup) {
        this.selectedPickupDate = null;
        this.carCriteria.pickupDateTime = null;
        this.dropOffCurrentDateOfTimeZone = this.calendarMinDate;
      }
      else {
        this.selectedDropoffDate = null;
        this.carCriteria.returnDateTime = null;
        this.isPickUpTimeLaterThanDropOffTime = false;
      }
    }
    if (this.carCriteria.productCode == undefined) {
      this.carCriteria.productCode = 'CAR';
    }
    this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');

    if (this.isDPSearch) {
      /*NEW IMPL - notify the change
      *change value : for DATE ->  PICKUPDATETIME~RETURNDATETIME*/
      let value = this.carCriteria.pickupDateTime + '~' + this.carCriteria.returnDateTime;

      let changeObj = {
        index : this.index,
        productCode : 'CAR',
        changeType : 'DATE',
        value : value
      };
      this.commonService.updateChangeItem(changeObj);
      this.validateInputsForDP();
    }

    if (!this.selectedDropoffDate) {
      setTimeout(() => {
        this.secondDatePickerFocus = true;
        this.guestComponentFocus = false;
        this.firstDatePickerFocus = false;
        this.isLastItemInMultiInput = false;
      }, this.setTimeoutValue);
    }

    if (this.secondDatePickerFocus) {
      setTimeout(() => {
        this.guestComponentFocus = false;
        this.firstDatePickerFocus = false;
        this.isLastItemInMultiInput = false;
      }, this.setTimeoutValue);
    }
    if (this.carCriteria && this.carCriteria.pickupDateTime) {
      this.travellerEarliestDepartureDateService
        .setEarliestDepartureDateFromComponentCriteriaForNewlyAddedItem(this.carCriteria.pickupDateTime);
    }
    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(true);
    }
  }

  getPassengerSelection($event: Array<PaxAllocationItem>) {
    if ($event != null && this.validateChildAges($event) && this.validateTeenAges($event)) {
      this.carCriteria.adult = this.processAdultPaxAllocation($event);

      const children = this.processChildAllocation($event);
      let teens = this.processTeenAllocation($event);
      const infant = this.processInfantAllocation($event);
      if (children.CHD && children.CHD.length > 0) {
        this.carCriteria.child = children.CHD;
      } else {
        this.carCriteria.child = null;
      }
      if (teens && teens.length > 0) {
        this.carCriteria.teen = teens;
      } else {
        this.carCriteria.teen = null;
      }
      this.carCriteria.infant = infant && infant.length > 0 ? infant : null;

      this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
    } else {
      this.carCriteria.adult = null;
      this.carCriteria.child = null;
      this.carCriteria.teen = null;
      this.carCriteria.infant = null;
      this.commonService.updateComponentCriteria(this.carCriteria, 'CAR');
    }

  }

  processInfantAllocation(paxArray: Array<PaxAllocationItem>) {

    let roomNo = 1;
    let inf = '';
    if (paxArray.length > 1){
      paxArray.forEach(paxRoom => {
        inf += (paxRoom.infant.length) + this.FIELD_SEPARATOR + this.FIELD_SEPARATOR + '__' + roomNo + '__' ;
        roomNo ++;
      });
    } else {
      inf = (paxArray[0].infant.length) + this.FIELD_SEPARATOR + this.FIELD_SEPARATOR ;
    }
    return inf;
  }
  processTeenAllocation(paxArray: Array<PaxAllocationItem>) {

    let teens = '';
    // teens
    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.teen.length > 0) {

        let teenAgeMap = {};
        const teenOrder = [];
        paxRoom.teen.forEach(teenAge => {
          if (teenOrder.findIndex(age => age === teenAge) < 0) {
            teenOrder.push(teenAge);
          }
        });
        paxRoom.teen.forEach(teenAge => {
          teenAgeMap[teenAge] ? teenAgeMap[teenAge]++ : teenAgeMap[teenAge] = 1;
        });
        for (let age of teenOrder) {
          const key = age + '';
          let count = teenAgeMap[key] + '';
          /* TODO update this logic based on guest age setup */
          /*teens*/
          teens += count + this.FIELD_SEPARATOR + key + this.FIELD_SEPARATOR + 'DOB';
          teens += this.AGE_SEPARATOR;
        }
      }
      roomNo++;
    });
    if (teens.length > 2) {
      teens = teens.slice(0, teens.length - 2);
    }
    return teens;
  }
  processChildAllocation(paxArray: Array<PaxAllocationItem>) {

    const children = {
      INF: '',
      CHD: '',
    };

    let roomNo = 1;
    paxArray.forEach(paxRoom => {
      if (paxRoom.child.length > 0) {

        const childAgeMap = {};
        const childOrder = [];
        paxRoom.child.forEach(childAge => {
          if (childOrder.findIndex(age => age === childAge) < 0) {
            childOrder.push(childAge);
          }
        });
        paxRoom.child.forEach(childAge => {
          childAgeMap[childAge] ? childAgeMap[childAge]++ : childAgeMap[childAge] = 1;
        });

        for (const age of childOrder) {
          const key = age + '';
          const count = childAgeMap[key] + '';
          if (key.trim() === this.LESS_THAN_ONE) {
            /*Infants*/
            // children.INF += count + fieldSeperator + 0 + fieldSeperator + this.convertDateObjToStr(this.getDOB(0)) + fieldSeperator + roomNo + ageSeperator;
            children.INF += count + this.FIELD_SEPARATOR + 0 + this.FIELD_SEPARATOR + 'DOB' + this.FIELD_SEPARATOR + roomNo + this.AGE_SEPARATOR;
          } else {
            /*child*/
            // children.CHD += count + fieldSeperator + key + fieldSeperator + this.convertDateObjToStr(this.getDOB(key)) + fieldSeperator + roomNo + ageSeperator;
            children.CHD += count + this.FIELD_SEPARATOR + key + this.FIELD_SEPARATOR + 'DOB' + this.FIELD_SEPARATOR + roomNo + this.AGE_SEPARATOR;
          }
        }
      }
      roomNo++;
    });

    if (children.INF.length > 2) {
      children.INF = children.INF.slice(0, children.INF.length - 2);
    }
    if (children.CHD.length > 2) {
      children.CHD = children.CHD.slice(0, children.CHD.length - 2);
    }

    return children;
  }

  processAdultPaxAllocation(paxArray: Array<PaxAllocationItem>) {

    const defaultAdultAge =  this.DEFAULT_ADULT_AGE;
    const fieldSeperator = '~';
    const roomSeperator = '__';

    let paxStr = '';

    for (let i = 0 ; i < paxArray.length ; i++) {
      const paxItem = paxArray[i];
      if (i !== 0) {
        paxStr = paxStr + roomSeperator;
      }
      // paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + this.convertDateObjToStr(this.getDOB(defaultAdultAge)) + fieldSeperator +  (i + 1);
      paxStr = paxStr + paxItem.adult + fieldSeperator + defaultAdultAge + fieldSeperator + 'DOB' + fieldSeperator + (i + 1);
    }

    return paxStr;
  }

  /**
   * check for invalid child ages
   * @param selectedPax
   */
  validateChildAges(selectedPax: Array<PaxAllocationItem> ) {
    return selectedPax.reduce((acc, pax) => acc && ( pax.child.indexOf(-1) > -1 ? false : true ), true);
  }
  validateTeenAges(selectedPax : Array<PaxAllocationItem> ){
    return selectedPax.reduce((acc, pax) => acc && ( pax.teen.indexOf(-1) > -1 ? false : true ), true);
  }
  getCurrentOption($event) {
    this.currentPaxOption = $event;
  }
  /**
   * handle focusout
   */
  onFocusOut($event, component) {
    if (component === 1) {
      this.guestComponentFocus = false;
    } else if (component === 2) {
      this.firstDatePickerFocus = false;
      this.secondDatePickerFocus = false;
    }
  }

  handleFocusIn(event) {
    if (event && event === 'DROP' && this.carCriteria && !this.carCriteria.returnCity) {
      this.showDropOffRecommendations = true;
    }
  }

  clearFields(event) {
    const pickItem = event.find( item => item.id === 'PICKUP');
    if(pickItem?.isFocused && pickItem.selectedItem.displayText === '' && this.autoFilled){
      this.selectedDropOffPoint.displayText = '';
    }
  }

  /**
   * handle key down
   */
  onKeyDown($event, component) {
    if ($event.keyCode === KeyCodes.ENTER ) {
      this.guestComponentFocus = false;
      this.firstDatePickerFocus = false;
      this.secondDatePickerFocus = false;
      if (component === 0) {
        setTimeout(() => {
          this.secondDatePickerFocus = false;
          this.guestComponentFocus = false;
          if (this.isLastItemInMultiInput === true) {
            this.firstDatePickerFocus = true;
            this.isLastItemInMultiInput = false;
          }
        }, this.setTimeoutValue);
      } else if (component === 1) {
        if (this.currentPaxOption === 3) {
          if (this.enterPressed && (this.carCriteria.nights === undefined || this.carCriteria.nights === 0)) {
            setTimeout(() => {
              this.guestComponentFocus = false;
              this.firstDatePickerFocus = false;
              this.secondDatePickerFocus = false;
            }, this.setTimeoutValue);
          }
          this.enterPressed = true;
        }
        if (this.currentPaxOption !== 3 && (this.carCriteria.nights === undefined || this.carCriteria.nights === 0)) {
          setTimeout(() => {
            this.guestComponentFocus = false;
            this.firstDatePickerFocus = false;
            this.secondDatePickerFocus = false;
          }, this.setTimeoutValue);
          this.enterPressed = false;
        }
      } else if (component === 2) {
        setTimeout(() => {
          this.guestComponentFocus = false;
          this.firstDatePickerFocus = false;
          this.secondDatePickerFocus = false;
          this.onKeyDownLast.emit(true);
        }, this.setTimeoutValue);
      }
      if (!this.isDPSearch) {
        this.lastKeyEnter = true;
      }
    } else {
      this.lastKeyEnter = false;
    }

    if ($event.keyCode === KeyCodes.UPARROW || $event.keyCode === KeyCodes.DOWNARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = false;
      }
    }

    if ($event.keyCode === KeyCodes.RIGHTARROW) {
      if (component === 1 && this.currentPaxOption === 3) {
        this.enterPressed = true;
      }
    }
  }

  getSearchQuery(event) {
    if (event) {
      if (event.length < this.MIN_SEARCH_QUERY_LENGTH) {
        this.isQueryError = false;
      } else {
        this.isQueryError = true;
      }
    }
  }

  updateDPCriteria(event) {
    /*in the DP flow, update the relavent product criteria inside the product criteria list*/
    let idx = this.surfCriteriaUtil.getCriteriaItemArrayIndex(this.productCriteriaList, 'CAR', this.index);
    if (this.productCriteriaList && this.productCriteriaList[idx]) {
      this.mapToDPCriteriaItem(this.productCriteriaList[idx], this.carCriteria, event);
    }
    else {
      let carCriItem = this.surfCriteriaUtil.getNewCriItem('CAR', this.index);
      carCriItem.driverAge = this.DEFAULT_DRIVER_AGE;
      this.mapToDPCriteriaItem(carCriItem, this.carCriteria, event);
      this.productCriteriaList.push(carCriItem);
    }
    this.validateInputsForDP();
    this.commonService.updateProductCriteriaList(this.productCriteriaList);
  }

  mapToDPCriteriaItem(dpCriItem, criItem, event) {
    if (event == 'dest-name') {
      if (criItem.destinationName) {
        dpCriItem.destinationName = JSON.parse(JSON.stringify(criItem.destinationName));
      }
    }

    if (event == 'pickup-return') {
      if (criItem.pickupCity) {
        dpCriItem.pickupCity = JSON.parse(JSON.stringify(criItem.pickupCity));
      }
      if (criItem.pickupLocation) {
        dpCriItem.pickupLocation = JSON.parse(JSON.stringify(criItem.pickupLocation));
      }
      if (criItem.returnCity) {
        dpCriItem.returnCity = JSON.parse(JSON.stringify(criItem.returnCity));
      }
      if (criItem.returnLocation) {
        dpCriItem.returnLocation = JSON.parse(JSON.stringify(criItem.returnLocation));
      }
    }

    if (event == 'date-time') {
      if (criItem.returnDateTime) {
        dpCriItem.returnDateTime = JSON.parse(JSON.stringify(criItem.returnDateTime));
      }
      if (criItem.pickupDateTime) {
        dpCriItem.pickupDateTime = JSON.parse(JSON.stringify(criItem.pickupDateTime));
      }
    }

    if(event == 'driver-age') {
      if (criItem.driverAge) {
        dpCriItem.driverAge = criItem.driverAge;  //driverAge is of type number. Therefore, no need of JSON manipulations
      }
    }

    if(event == 'no-of-cars'){
      if (criItem.noOfCars) {
        dpCriItem.noOfCars = JSON.parse(JSON.stringify(criItem.noOfCars));
      }
    }
  }

  /**
   * check focusing input is the last input of multi field input
   * param isLastItem
   */
  ShowLastElement(isLastItem) {
    this.isLastItemInMultiInput = isLastItem;
  }

  /**
   * focus to DROP-OFF DATE & TIME date picker
   */
  focusToSecondDatepicker() {
    this.firstDatePickerFocus = false;
    this.secondDatePickerFocus = true;
  }

  generateDisplayString(adult, child, infant, teen) {

    // should handle
    let str = '';

    if (adult === 1 && child === 0 && infant === 0 && teen === 0) {
      /*solo traveller*/
      str += '1 adult';
    } else if (adult > 1 && child === 0 && infant === 0 && teen === 0) {
      /*couple or pair or more than 1 adults*/
      str += adult + ' adults';
    } else {
      /*Group or family*/
      const tot = adult + child + infant + teen;
      str += tot + ' guests';
    }
    return str;
  }

  // Following method creates the car contract types
  createCarContractTypes() {
    this.carContractTypes = [];
    this.selectedContractType = this.DEFAULT_CONTRACT_TYPE;
    if (!this.carCriteria.contractType) { this.carCriteria.contractType = this.selectedContractType; }
    Object.entries(CAR_CONTRACT_TYPES).forEach(([key, value]) => {
      if (key === this.DEFAULT_CONTRACT_TYPE) {
        this.carContractTypes.push({code: key, value, selected: true });
      } else {
        this.carContractTypes.push({code: key, value, selected: false });
      }
    });
  }

  setSelectedCarContractType(selectedContractType: string) {
    if (!selectedContractType) {
      selectedContractType = this.DEFAULT_CONTRACT_TYPE;
    }
    this.carContractTypes.forEach( (contractType) => {
      if (contractType.code === selectedContractType) {
        this.selectedContractType = selectedContractType;
        contractType.selected = true;
      } else  {
        contractType.selected = false;
      }
    });
  }

  handleContractTypeDropDownClick(event: MouseEvent) {
    event.stopPropagation();
  }

  handleContractTypeSelection($event) {
    this.selectedContractType = $event.code;
    this.carCriteria.contractType = this.selectedContractType;
    if (this.isAmendmentFlow && !this.isItemCloseable) {
      this.checkTypeChange();
    }
  }

  emitOutside() {
    if (this.isDPSearch) {
      this.validateInputsForDP();
    }
  }

  /**
   * handle freeze-unfreeze checkbox
   */
  checkValue(event) {
    this.unfreeze = event.target.checked;
    this.freeze = !this.unfreeze;
    this.isUnfreeze.emit(event.target.checked);
    if (!this.dpInitialDestStr && this.isAmendmentFlow) {
      if (!(this.isDPSearch && this.freeze)) {
        this.typeAheadErrorMsg = 'The previous location is unavailable. Please select a location and continue';
        this.userCaption = '';
        this.isFilterListError = true;
      } else {
        this.userCaption =
          'Looks like the previous location is unavailable. Select this item to amend the location. Alternatively click Search if you wish to continue without amending the location.';
        this.isFilterListError = false;
      }
      // this.typeAheadErrorMsg = 'The previous location is unavailable. Please select a location and continue';
    } else {
      this.typeAheadErrorMsg = '';
      this.userCaption = '';
      this.isFilterListError = false;
    }
  }

  /**
   * set warning true and false with the PickUp/DropOff location input change, by comparing with the initial value.
   */
  checkLocationChange(group) {
    let pickLocationChange = false;
    let dropLocationChange = false;
    const pickUpName = this.carCriteria.pickupLocationName;
    const dropOffName = this.carCriteria.returnLocationName;
    const pickupCity = this.carCriteria.pickupCity;
    const dropOffCity = this.carCriteria.returnCity;
    if (this.oldCriteriaItem && this.oldCriteriaItem.criItem) {
      if (group) {
        if (pickUpName !== this.oldCriteriaItem.criItem.pickupLocationName || pickupCity !== this.oldCriteriaItem.criItem.pickupCity ||
          group !== this.oldCriteriaItem.criItem.pickupGrp) {
          pickLocationChange = true;
          dropLocationChange = true;
        }
        if (dropOffName !== this.oldCriteriaItem.criItem.returnLocationName || dropOffCity !== this.oldCriteriaItem.criItem.returnCity ||
          group !== this.oldCriteriaItem.criItem.returnGrp) {
          dropLocationChange = true;
        }
      } else {
        if (pickUpName !== this.oldCriteriaItem.criItem.pickupLocationName || pickupCity !== this.oldCriteriaItem.criItem.pickupCity) {
          pickLocationChange = true;
          dropLocationChange = true;
        }
        if (dropOffName !== this.oldCriteriaItem.criItem.returnLocationName || dropOffCity !== this.oldCriteriaItem.criItem.returnCity) {
          dropLocationChange = true;
        }
      }
      this.pickUpDropOffProperties[0].warning = pickLocationChange;
      this.pickUpDropOffProperties[1].warning = dropLocationChange;
    }
  }

  /**
   * set warning true and false with the Type input change, by comparing with the initial value.
   */
  checkTypeChange() {
    let type;
    if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && !this.oldCriteriaItem.criItem.contractType &&
      this.carContractTypes.length > 0) {
      type = this.carContractTypes[0].code;
    } else if (this.oldCriteriaItem && this.oldCriteriaItem.criItem && this.oldCriteriaItem.criItem.contractType) {
      type = this.oldCriteriaItem.criItem.contractType;
    }
    this.typeWarning = !(this.oldCriteriaItem && (this.selectedContractType === type));
  }

  /**
   * set warning true and false with the driver age change, by comparing with the initial value.
   */
  checkAgeChange() {
    this.ageWarning = !(this.oldCriteriaItem && (this.selectedDriverAge === this.oldCriteriaItem.criItem.driverAge));
  }

  /**
   * set warning true and false with the PickUP/DropOff input change, by comparing with the initial value.
   */
  checkDateChange(pickup) {
    if (pickup) {
      this.pickUpDateWarning = !(this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
        this.carCriteria.pickupDateTime === this.oldCriteriaItem.criItem.pickupDateTime);
    } else {
      this.dropOffDateWarning = !(this.oldCriteriaItem && this.oldCriteriaItem.criItem &&
        this.carCriteria.returnDateTime === this.oldCriteriaItem.criItem.returnDateTime);
    }
    if (this.isAmendmentFlow) {
      this.doCheckLastMinuteBookingDate(false);
    }
  }

  setDefaultPickUpAndDropOffTimes() {
  const userLang = navigator.language;
    if (this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES &&
      this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours &&
      this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.minutes &&
      this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours &&
      this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.minutes) {

      if (this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours &&
        parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10) &&
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours &&
        parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10)) {

        // pickUp
        this.defaultPickUpMeridian = parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10)
        >= 12 ? 'PM' : 'AM';
        // dropOff
        this.defaultDropOffMeridian = parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10)
        >= 12 ? 'PM' : 'AM';
      }

      if (parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10) > 12) {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours =
          parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10);
      } else if (parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10) === 0) {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours = 12;
      } else {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours =
          parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours, 10);
      }
      if (parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10) > 12) {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours =
          parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10);
      } else if (parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10) === 0) {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours = 12;
      } else {
        this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours =
          parseInt(this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours, 10);
      }

      // pickUp
      this.defaultPickUpTimesHours = this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.hours.toString();
      this.defaultPickUpTimesMinutes = this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.pickUpTime.minutes;

      // dropOff
      this.defaultDropOffTimesHours = this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.hours.toString();
      this.defaultDropOffTimesMinutes = this.CAR_AUTO_POPULATING_PICKUP_DROP_OFF_DEFAULT_TIMES.dropOffTime.minutes;
    }
  }

  /**
   * to check is 24 hour or 12 hour format according to browser locale
   * @param locale - browser language
   */
  isTwentyFourHours(locale: string) {
    const timePipe = new SurfTimePipe();
    const date = new Date();
    const timeStr = timePipe.transform(date.getTime(), DateTimeFormat.SIMPLE_TIME_FORMAT, navigator.language);

    if (timeStr.indexOf('AM') > 0 || timeStr.indexOf('PM') > 0) {
      return false;
    } else {
      return true;
    }
  }

  doCheckLastMinuteBookingDate(isAmendmentOldItemDateSelection: boolean) {
    if (this.selectedPickupDate) {
      let checkingDate = this.selectedPickupDate;
      if (this.surfCalendarStartDateService.validateWithCheckingIsEarlierDate(new Date(), checkingDate, 'CAR')) {
        this.hideErrorMsg = true;
        this.validateDisabledDateSelection = false;
        this.surfCalendarStartDateService.lastMinuteBookingErrorSet.delete(this.index);
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = true;
        }
      } else {
        this.surfCalendarStartDateService.lastMinuteBookingErrorSet.add(this.index);
        this.hideErrorMsg = false;
        this.validateDisabledDateSelection = true;
        if (isAmendmentOldItemDateSelection) {
          this.surfCalendarStartDateService.hasValidUnfreezeItem = false;
        }
      }
    }
  }

  getDropOffInvalidErrorMessage () : string {
    return (this.isPickUpTimeLaterThanDropOffTime ? this.PICKUP_LATER_THAN_DROP_OFF_ERROR_MESSAGE :
            this.validateDisabledDateSelection ? this.SURF_ERROR_MESSAGES.LAST_MINUTE_BOOKING_VALIDATION_ERROR_MESSAGE :
            'Invalid Time');
  }
}
